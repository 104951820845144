// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-black-friday-js": () => import("./../../../src/pages/black-friday.js" /* webpackChunkName: "component---src-pages-black-friday-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-form-success-js": () => import("./../../../src/pages/form-success.js" /* webpackChunkName: "component---src-pages-form-success-js" */),
  "component---src-pages-how-to-financial-js": () => import("./../../../src/pages/how-to-financial.js" /* webpackChunkName: "component---src-pages-how-to-financial-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-integrations-js": () => import("./../../../src/pages/integrations.js" /* webpackChunkName: "component---src-pages-integrations-js" */),
  "component---src-pages-logos-resources-js": () => import("./../../../src/pages/logos-resources.js" /* webpackChunkName: "component---src-pages-logos-resources-js" */),
  "component---src-pages-mobile-payments-service-provider-js": () => import("./../../../src/pages/mobile-payments-service-provider.js" /* webpackChunkName: "component---src-pages-mobile-payments-service-provider-js" */),
  "component---src-pages-newsletter-success-js": () => import("./../../../src/pages/newsletter-success.js" /* webpackChunkName: "component---src-pages-newsletter-success-js" */),
  "component---src-pages-online-payment-services-travel-agencies-js": () => import("./../../../src/pages/online-payment-services-travel-agencies.js" /* webpackChunkName: "component---src-pages-online-payment-services-travel-agencies-js" */),
  "component---src-pages-payment-links-js": () => import("./../../../src/pages/payment-links.js" /* webpackChunkName: "component---src-pages-payment-links-js" */),
  "component---src-pages-payment-modules-js": () => import("./../../../src/pages/payment-modules.js" /* webpackChunkName: "component---src-pages-payment-modules-js" */),
  "component---src-pages-payment-modules-shopify-js": () => import("./../../../src/pages/payment-modules/shopify.js" /* webpackChunkName: "component---src-pages-payment-modules-shopify-js" */),
  "component---src-pages-payment-service-provider-for-ecommerce-js": () => import("./../../../src/pages/payment-service-provider-for-ecommerce.js" /* webpackChunkName: "component---src-pages-payment-service-provider-for-ecommerce-js" */),
  "component---src-pages-payment-services-betting-gambling-js": () => import("./../../../src/pages/payment-services-betting-gambling.js" /* webpackChunkName: "component---src-pages-payment-services-betting-gambling-js" */),
  "component---src-pages-price-calculator-js": () => import("./../../../src/pages/price-calculator.js" /* webpackChunkName: "component---src-pages-price-calculator-js" */),
  "component---src-pages-pricing-js": () => import("./../../../src/pages/pricing.js" /* webpackChunkName: "component---src-pages-pricing-js" */),
  "component---src-pages-product-js": () => import("./../../../src/pages/product.js" /* webpackChunkName: "component---src-pages-product-js" */),
  "component---src-pages-secure-online-payment-services-pci-dss-js": () => import("./../../../src/pages/secure-online-payment-services-pci-dss.js" /* webpackChunkName: "component---src-pages-secure-online-payment-services-pci-dss-js" */),
  "component---src-pages-shopify-payment-service-provider-js": () => import("./../../../src/pages/shopify-payment-service-provider.js" /* webpackChunkName: "component---src-pages-shopify-payment-service-provider-js" */),
  "component---src-templates-blog-list-js": () => import("./../../../src/templates/blog-list.js" /* webpackChunkName: "component---src-templates-blog-list-js" */),
  "component---src-templates-blog-post-js": () => import("./../../../src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */),
  "component---src-templates-blog-tag-list-js": () => import("./../../../src/templates/blog-tag-list.js" /* webpackChunkName: "component---src-templates-blog-tag-list-js" */),
  "component---src-templates-faq-page-js": () => import("./../../../src/templates/faq-page.js" /* webpackChunkName: "component---src-templates-faq-page-js" */),
  "component---src-templates-markdown-page-js": () => import("./../../../src/templates/markdown-page.js" /* webpackChunkName: "component---src-templates-markdown-page-js" */),
  "component---src-templates-module-page-js": () => import("./../../../src/templates/module-page.js" /* webpackChunkName: "component---src-templates-module-page-js" */),
  "component---src-templates-special-terms-and-conditions-js": () => import("./../../../src/templates/special-terms-and-conditions.js" /* webpackChunkName: "component---src-templates-special-terms-and-conditions-js" */)
}

